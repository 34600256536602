import countries from 'i18n-iso-countries';
import momentTZ from 'moment-timezone';

// have to do this to get country names in english
countries.registerLocale(require('i18n-iso-countries/langs/en.json'));

export const announcementMessageTypes = [
  'ALERT',
  'PROMOTION',
];

export const autoTranslationLanguages = [
  { label: 'Arabic', value: 'ar' },
  { label: 'Catalan', value: 'ca' },
  { label: 'Chinese (Simplified)', value: 'zh-Hans' },
  { label: 'Chinese (Traditional)', value: 'zh-Hant' },
  { label: 'Croatian', value: 'hr' },
  { label: 'Czech', value: 'cs' },
  { label: 'Danish', value: 'da' },
  { label: 'Dutch', value: 'nl' },
  { label: 'English (GB)', value: 'en-GB' },
  { label: 'Finnish', value: 'fi' },
  { label: 'French', value: 'fr' },
  { label: 'French (Canada)', value: 'fr-CA' },
  { label: 'German', value: 'de' },
  { label: 'Greek', value: 'el' },
  { label: 'Hebrew', value: 'he' },
  { label: 'Hindi', value: 'hi' },
  { label: 'Hungarian', value: 'hu' },
  { label: 'Indonesian', value: 'id' },
  { label: 'Italian', value: 'it' },
  { label: 'Japanese', value: 'ja' },
  { label: 'Korean', value: 'ko' },
  { label: 'Malay', value: 'ms-MY' },
  { label: 'Norwegian Bokmal', value: 'nb' },
  { label: 'Polish', value: 'pl' },
  { label: 'Portuguese (Brazil)', value: 'pt-BR' },
  { label: 'Portuguese (Portugal)', value: 'pt-PT' },
  { label: 'Pseudo', value: 'en-XA' },
  { label: 'Pseudo (BiDi)', value: 'ar-XB' },
  { label: 'Russian', value: 'ru' },
  { label: 'Slovak', value: 'sk' },
  { label: 'Slovenian', value: 'sl' },
  { label: 'Spanish (Argentina)', value: 'es-AR' },
  { label: 'Spanish (Latin America)', value: 'es-419' },
  { label: 'Spanish (Spain)', value: 'es-ES' },
  { label: 'Spanish (Mexico)', value: 'es-MX' },
  { label: 'Swedish', value: 'sv' },
  { label: 'Thai', value: 'th' },
  { label: 'Turkish', value: 'tr' },
  { label: 'Vietnamese', value: 'vi' },
];

export const brandValues = ['NIKE', 'CONVERSE', 'FOOTLOCKER'];

export const businessConceptValues = [
  'BEACON', 'BRAND_POP_UP',
  'CLEARANCE', 'COMMUNITY',
  'EMPLOYEE_STORE',
  'FACTORY',
  'KICKS_LOUNGE',
];

export const conceptCodeValues = [
  'Beacon', 'Brand Pop Up',
  'Clearance', 'Community',
  'Employee Store',
  'Factory',
  'INLINE',
  'Kicks Lounge',
  'NikeLab', 'NikeTown',
  'POP UP',
  'Running',
  'Traditional',
  'Womens',
];

export const concepts = [
  { label: 'AIRPORT', value: 'AIRPORT' },
  { label: 'BEACON_STORE', value: 'BEACON' },
  { label: 'CLEARANCE_STORES', value: 'CLEARANCE' },
  { label: 'COMMUNITY_STORE', value: 'COMMUNITY' },
  { label: 'DISTRIBUTION_CENTER', value: 'DC' },
  { label: 'EMPLOYEE_STORE', value: 'EMPLOYEE' },
  { label: 'GOLF_ONLY_STORE', value: 'GOLF' },
  { label: 'NIKE_RUNNING', value: 'RUNNING' },
  { label: 'NIKE_TOWN', value: 'NIKETOWN' },
  { label: 'NIKELAB', value: 'NIKELAB' },
  { label: 'ONLINE_STORE', value: 'COM' },
  { label: 'POP_UP_STORE', value: 'POP_UP' },
  { label: 'SATELLITE_DISTRIBUTION_CENTER', value: 'SDC' },
  { label: 'TRADITIONAL_STORE', value: 'TRADITIONAL' },
  { label: 'WOMENS_ONLY', value: 'WOMENS' },
];

export const companyValues = [
  'Nike',
  'AJU_SPORTS', 'ANDONG', 'AOLONGSHIBO', 'AXO',
  'Barcin', 'BD_SHENGSHI', 'BELEP', 'BONGDEOK',
  'CC_YUJIE', 'CHILGOK', 'CHILSEONG', 'CHUNCHEON', 'Converse',
  'DAEJEON_JEIL', 'DAERIM_SPORTS_DS_BETTER', 'DAESAN_SPORTS', 'DAEYEON', 'DARIM', 'DONGDAEGU_SPORTS', 'DONGHUI',
  'EO_SPORTS', 'EPIC_SPORTS', 'Equinox_Chile_Sociedad_por_Acciones', 'EUNGWANG',
  'FAYA', 'FFG', 'Fox', 'FootLocker',
  'GANGNEUNG', 'GOODBABY', 'GWANGJANG', 'GYEONGSAN', 'GYEONGSEONG_BUGYEONG',
  'HADDAD', 'HAETAE_SANGSA_SINJANGAN', 'HANHWA_GALLERIA_DEPT_BEST', 'HANYEONG_YUTONG_YEONGTONG', 'HIGHWAVE_SPORTS', 'HYUNDAI',
  'IRI', 'ITAEWON_TOWN',
  'JANGNIM_SPORTS', 'JECHEON', 'JEOMCHON', 'JEONJU_SPORTS', 'JIEZHIXING', 'JIN_SPORTS', 'JUNGNANG',
  'KU_Hoops',
  'MASAN_HAPSEONG', 'Masplay', 'MOKPO_SPORTS', 'MUNHEUNG', 'MUNSAN',
  'Nordstrom', 'NANUM_SPORTS_GWANGJU', 'NARA', 'NAUN_SPORTS', 'NEW_SEONGAN',
  'Percassi', 'Permashop', 'PI_AND_CO', 'PouSheng',
  'Really', 'RUN_AND_FUN_SPORTS',
  'SANGJU', 'SANSE', 'SHAANXI_LIHE', 'SHOELAND', 'SHUANGJIAN', 'SIJI_SPORTS', 'SINHYEON_SPORTS', 'SINJEJU', 'SMH', 'SOKCHO', 'SONGTAN', 'Southbay_SRL', 'SPORTINN', 'SPORTS_MEDIA', 'SPORTS_SEVEN_VALLEY', 'SUNCHEON', 'SUHYEON_21', 'SUWON_SPORTS',
  'TAEBAEK', 'TAEJIN_SPORTS', 'TopSports',
  'VENTURE_YUTONG',
  'WINWIN', 'WONJU',
  'YEONGCHEON', 'YEONGJU',
];

export const countryValues = countries.getAlpha3Codes();

export const countryOptions = Object.keys(countryValues).map((code) => ({
  // TODO: remove Türkiye hardcoding when dependent module releases a version with the new name
  label: code === 'TUR' ? 'Türkiye' : countries.getName(code, 'en'),
  value: code,
}));

export const cmpCountries = ['USA', 'PRI'];

export const currencyCodeValues = [
  'AED', 'AFN', 'ALL', 'AMD', 'ANG', 'AOA', 'ARS', 'AUD', 'AWG', 'AZN',
  'BAM', 'BBD', 'BDT', 'BGN', 'BHD', 'BIF', 'BMD', 'BND', 'BOB', 'BRL', 'BSD', 'BTN', 'BWP', 'BYR', 'BZD',
  'CAD', 'CDF', 'CHF', 'CLP', 'CNY', 'COP', 'CRC', 'CSD', 'CUP', 'CVE', 'CZK',
  'DJK', 'DKK', 'DOP', 'DZD',
  'EGP', 'ERN', 'ETB', 'EUR',
  'FJD', 'FKP', 'FOK',
  'GBP', 'GEL', 'GHC', 'GIP', 'GMD', 'GNF', 'GTQ', 'GWP', 'GYD',
  'HKD', 'HNL', 'HRK', 'HTG', 'HUF',
  'IDR', 'IEP', 'ILS', 'INR', 'IQD', 'IRR', 'ISK', 'ITL',
  'JMD', 'JOD', 'JPY',
  'KES', 'KGS', 'KHR', 'KMF', 'KPW', 'KRW', 'KWD', 'KYD', 'KZT',
  'LAK', 'LBP', 'LKR', 'LRD', 'LSL', 'LYD',
  'MAD', 'MDL', 'MGF', 'MKD', 'MMK', 'MNT', 'MOP', 'MRO', 'MTL', 'MUR', 'MVR', 'MWK', 'MXN', 'MYR', 'MZM',
  'NAD', 'NGN', 'NIO', 'NOK', 'NPR', 'NZD',
  'OMR',
  'PAB', 'PEN', 'PGK', 'PHP', 'PKR', 'PLN', 'PYG',
  'QAR',
  'ROL', 'RON', 'RSD', 'RUB', 'RUR', 'RWF',
  'SAR', 'SBD', 'SCR', 'SDG', 'SEK', 'SGD', 'SHP', 'SKK', 'SLL', 'SOS', 'SRG', 'SSP', 'STD', 'SVC', 'SYP', 'SZL',
  'THB', 'TJS', 'TMM', 'TMT', 'TND', 'TOP', 'TRY', 'TTD', 'TWD', 'TZS',
  'UAH', 'UGX', 'USD', 'USN', 'USS', 'UYU', 'UZS',
  'VEF', 'VND', 'VUV',
  'WST',
  'XAF', 'XCD', 'XOF', 'XPF',
  'YER',
  'ZAR', 'ZMW', 'ZWD',
];

export const defaultNFSImageUrl = 'https://static.nike.com/a/images/f_auto/d2f5c06a-629d-4f20-a092-9e56315a80b3/image.jpeg';

export const defaultNSPImageUrl = 'https://static.nike.com/a/images/f_auto/2e8d9338-b43d-4ef5-96e1-7fdcfd838f8e/image.jpg';

export const defaultOfferingImageUrl = 'https://static.nike.com/a/images/f_auto/7cb8e7f1-ec92-4827-a7ac-a0d91fffae08/image.png';

export const dimensionCodeValues = ['DIGITAL', 'EUR', 'GA', 'NFS', 'NSO', 'TMALL'];

export const dimensions = [
  { label: 'CLEARANCE_STORES', value: 'CLEARANCE' },
  { label: 'GENERAL_AVAILABILITY', value: 'GA' },
  { label: 'NIKE_COM', value: 'DIGITAL' },
  { label: 'NIKE_FACTORY_STORES', value: 'NFS' },
  { label: 'NIKE_STORES_OWNED', value: 'NSO' },
  { label: 'NIKE_STORES_PARTNERED', value: 'NSP' },
];

export const facilityTypeValues = [
  'NIKE_OWNED_STORE',
  'FRANCHISEE_PARTNER_STORE',
  'MONO_BRAND_NON_FRANCHISEE_PARTNER_STORE',
  'MULTI_BRAND_NON_FRANCHISEE_PARTNER_STORE',
  'POPUP_STORE',
  'NIKE_STORE_EXTENSION',
];

export const serviceStatusValues = [
  'ENABLED',
  'DISABLED',
];

export const IPTempClosureReasonValues = [
  '',
  'NATURAL_DISASTER',
  'OVERCAPACITY',
  'PERM_CLOSURE_PREPARATION',
  'REMODEL',
  'UNFORSEEN_EVENTS',
];

export const IPPlatformValues = [
  'BEACON',
  'CITY_DOOR', 'CLEARANCE_DOOR',
  'DROP_SHIP',
  'EMPLOYEE_STORE',
  'GLOBAL_EXPANSION',
  'HOOPS',
  'IP_BY_ACCOUNT',
  'NIKE_COM', 'NIKEID',
  'OUTLET_DOOR',
  'REVERSE_DROP_SHIP', 'RUNNING_DOOR',
  'SOCIAL_COMMERCE',
  'WOMENS_DOOR',
];

export const IPChannelValues = [
  'DIGITAL',
  'NFS',
  'NSO',
  'NSP',
];

export const IPTerritory = ['', 'CENTRAL', 'EAST', 'NORTH', 'SOUTH', 'WEST'];

export const IPSubTerritory = [
  '',
  'AGS_CENTRAL_CH', 'AGS_NORTH', 'AGS_SOUTH_AU',
  'BERLIN',
  'CENTRAL_EUROPE',
  'FRANCE_NORTH', 'FRANCE_SOUTH',
  'GERMAN_MID_SWITZERLAND', 'GERMAN_NORTH', 'GERMAN_SOUTH_AUSTRIA', 'GREECE',
  'ISRAEL', 'ITALY', 'ITALY_MILAN',
  'LONDON',
  'NE_CENTRAL', 'NE_NORTH', 'NE_SOUTH', 'NTL',
  'PARIS_NORTH', 'PARIS_SOUTH', 'POLAND',
  'RUSSIA_MOSCOW', 'RUSSIA_ST_PETERSBURG',
  'SOUTH_AFRICA', 'SPAIN', 'SPAIN_BARCELONA', 'SPAIN_PORTUGAL',
  'TURKEY', 'TURKEY_ISTANBUL',
  'UK_EAST', 'UK_IRELAND_WEST', 'UK_NORTH', 'UK_SOUTH',
];

export const IPTimezones = [
  '',
  'BST',
  'CEST', 'CET',
  'EEST', 'EET',
  'FET',
  'GMT',
  'IST',
  'KUYT',
  'MSD', 'MSK',
  'RST',
  'SAMT',
  'TRT',
  'WEST', 'WET',
];

export const jeCountries = [
  'ANDORRA', 'ARGENTINA', 'AUSTRIA',
  'BELGIUM',
  'CHILE', 'MAINLAND_CHINA', 'CROATIA', 'CZECH_REPUBLIC',
  'DENMARK',
  'FINLAND', 'FRANCE',
  'GERMANY', 'GREECE',
  'HONG_KONG', 'HUNGARY',
  'IRELAND', 'ISRAEL', 'ITALY',
  'JAPAN',
  'MACAO', 'MEXICO',
  'NETHERLANDS', 'NORWAY',
  'POLAND', 'PORTUGAL', 'PUERTO_RICO',
  'RUSSIA',
  'SLOVAKIA', 'SPAIN', 'SWEDEN', 'SWITZERLAND',
  'TAIWAN', 'TURKEY',
  'URUGUAY', 'UNITED_KINGDOM', 'UNITED_STATES',
];

export const jeDistricts = [
  { label: 'CE_CHINA_EAST', value: 'CE' },
  { label: 'CN_CHINA_EAST', value: 'CN' },
  { label: 'CN_CHINA_NORTH', value: 'CS' },
  { label: 'CS_CHINA_SOUTH', value: 'CS_CHINA_SOUTH' },
  { label: 'CW_CHINA_WEST', value: 'CW_CHINA_WEST' },
  { label: 'DEFAULT', value: 'DEFAULT' },
  { label: 'DISTRICT_1', value: 'DISTRICT_1' },
  { label: 'DISTRICT_2', value: 'DISTRICT_2' },
  { label: 'DISTRICT_3', value: 'DISTRICT_3' },
  { label: 'DISTRICT_4', value: 'DISTRICT_4' },
  { label: 'DISTRICT_5', value: 'DISTRICT_5' },
  { label: 'DISTRICT_6', value: 'DISTRICT_6' },
  { label: 'DISTRICT_7', value: 'DISTRICT_7' },
  { label: 'DISTRICT_8', value: 'DISTRICT_8' },
  { label: 'DISTRICT_9', value: 'DISTRICT_9' },
  { label: 'DISTRICT_10', value: 'DISTRICT_10' },
  { label: 'DISTRICT_11', value: 'DISTRICT_11' },
  { label: 'DISTRICT_12', value: 'DISTRICT_12' },
  { label: 'DISTRICT_13', value: 'DISTRICT_13' },
  { label: 'DISTRICT_14', value: 'DISTRICT_14' },
  { label: 'DISTRICT_17', value: 'DISTRICT_17' },
  { label: 'DISTRICT_18', value: 'DISTRICT_18' },
  { label: 'DISTRICT_19', value: 'DISTRICT_19' },
  { label: 'DISTRICT_20', value: 'DISTRICT_20' },
  { label: 'DISTRICT_21', value: 'DISTRICT_21' },
  { label: 'DISTRICT_22', value: 'DISTRICT_22' },
  { label: 'DISTRICT_23', value: 'DISTRICT_23' },
  { label: 'DISTRICT_24', value: 'DISTRICT_24' },
  { label: 'DISTRICT_25', value: 'DISTRICT_25' },
  { label: 'DISTRICT_26', value: 'DISTRICT_26' },
  { label: 'DISTRICT_27', value: 'DISTRICT_27' },
  { label: 'DISTRICT_28', value: 'DISTRICT_28' },
  { label: 'HK_HONGKONG', value: 'HK' },
  { label: 'MC_MACAU', value: 'MC' },
  { label: 'TW_TAIWAN', value: 'TW' },
];

export const jeRegions = [
  { label: 'EUROPE', value: 'EU' },
  { label: 'GREATER_CHINA', value: 'GC' },
  { label: 'JAPAN', value: 'JAPAN' },
  { label: 'MEXICO', value: 'MX' },
  { label: 'NORTH_AMERICA', value: 'NA' },
  { label: 'SOCO', value: 'SOCO' },
];

export const jeTerritories = [
  { label: 'ALL', value: 'ALL' },
  { label: 'ARGENTINA', value: 'AR' },
  { label: 'CENTRAL_EASTERN_EUROPE', value: 'CEE' },
  { label: 'CHILE', value: 'CL' },
  { label: 'CHINA_MAINLAND', value: 'ML' },
  { label: 'HONG_KONG', value: 'HK' },
  { label: 'MEXICO', value: 'MX' },
  { label: 'TAIWAN', value: 'TW' },
  { label: 'UNITED_STATES', value: 'US' },
  { label: 'URUGUAY', value: 'UY' },
  { label: 'WESTERN_EUROPE', value: 'WE' },
];

export const keyCityValues = [
  '',
  'BARCELONA', 'BEIJING',
  'BERLIN',
  'LONDON', 'LOS_ANGELES',
  'MEXICO_CITY', 'MILAN',
  'NEW_YORK',
  'PARIS',
  'SEOUL', 'SHANGHAI',
  'TOKYO',
];

export const isoCountryObjectArray = {
  aa: ['aa', 'Afar'],
  ab: ['ab', 'Abkhazian'],
  ae: ['ae', 'Avestan'],
  af: ['af', 'Afrikaans'],
  ak: ['ak', 'Akan'],
  am: ['am', 'Amharic'],
  an: ['an', 'Aragonese'],
  ar: ['ar', 'Arabic'],
  as: ['as', 'Assamese'],
  av: ['av', 'Avaric'],
  ay: ['ay', 'Aymara'],
  az: ['az', 'Azerbaijani'],
  ba: ['ba', 'Bashkir'],
  be: ['be', 'Belarusian'],
  bg: ['bg', 'Bulgarian'],
  bh: ['bh', 'Bihari languages'],
  bi: ['bi', 'Bislama'],
  bm: ['bm', 'Bambara'],
  bn: ['bn', 'Bengali'],
  bo: ['bo', 'Tibetan'],
  br: ['br', 'Breton'],
  bs: ['bs', 'Bosnian'],
  ca: ['ca', 'Catalan'],
  ce: ['ce', 'Chechen'],
  ch: ['ch', 'Chamorro'],
  co: ['co', 'Corsican'],
  cr: ['cr', 'Cree'],
  cs: ['cs', 'Czech'],
  cu: ['cu', 'Church Slavic'],
  cv: ['cv', 'Chuvash'],
  cy: ['cy', 'Welsh'],
  da: ['da', 'Danish'],
  de: ['de', 'German'],
  dv: ['dv', 'Maldivian'],
  dz: ['dz', 'Dzongkha'],
  ee: ['ee', 'Ewe'],
  el: ['el', '"Greek, Modern (1453-)"'],
  en: ['en', 'English'],
  eo: ['eo', 'Esperanto'],
  es: ['es', 'Spanish'],
  et: ['et', 'Estonian'],
  eu: ['eu', 'Basque'],
  fa: ['fa', 'Persian'],
  ff: ['ff', 'Fulah'],
  fi: ['fi', 'Finnish'],
  fj: ['fj', 'Fijian'],
  fo: ['fo', 'Faroese'],
  fr: ['fr', 'French'],
  fy: ['fy', 'Western Frisian'],
  ga: ['ga', 'Irish'],
  gd: ['gd', 'Gaelic'],
  gl: ['gl', 'Galician'],
  gn: ['gn', 'Guarani'],
  gu: ['gu', 'Gujarati'],
  gv: ['gv', 'Manx'],
  ha: ['ha', 'Hausa'],
  he: ['he', 'Hebrew'],
  hi: ['hi', 'Hindi'],
  ho: ['ho', 'Hiri Motu'],
  hr: ['hr', 'Croatian'],
  ht: ['ht', 'Haitian'],
  hu: ['hu', 'Hungarian'],
  hy: ['hy', 'Armenian'],
  hz: ['hz', 'Herero'],
  ia: ['ia', 'Interlingua (International Auxiliary Language Association)'],
  id: ['id', 'Indonesian'],
  ie: ['ie', 'Interlingue'],
  ig: ['ig', 'Igbo'],
  ii: ['ii', 'Sichuan Yi'],
  ik: ['ik', 'Inupiaq'],
  io: ['io', 'Ido'],
  is: ['is', 'Icelandic'],
  it: ['it', 'Italian'],
  iu: ['iu', 'Inuktitut'],
  ja: ['ja', 'Japanese'],
  jv: ['jv', 'Javanese'],
  ka: ['ka', 'Georgian'],
  kg: ['kg', 'Kongo'],
  ki: ['ki', 'Kikuyu'],
  kj: ['kj', 'Kuanyama'],
  kk: ['kk', 'Kazakh'],
  kl: ['kl', 'Kalaallisut'],
  km: ['km', 'Central Khmer'],
  kn: ['kn', 'Kannada'],
  ko: ['ko', 'Korean'],
  kr: ['kr', 'Kanuri'],
  ks: ['ks', 'Kashmiri'],
  ku: ['ku', 'Kurdish'],
  kv: ['kv', 'Komi'],
  kw: ['kw', 'Cornish'],
  ky: ['ky', 'Kirghiz'],
  la: ['la', 'Latin'],
  lb: ['lb', 'Luxembourgish'],
  lg: ['lg', 'Ganda'],
  li: ['li', 'Limburgan'],
  ln: ['ln', 'Lingala'],
  lo: ['lo', 'Lao'],
  lt: ['lt', 'Lithuanian'],
  lu: ['lu', 'Luba-Katanga'],
  lv: ['lv', 'Latvian'],
  mg: ['mg', 'Malagasy'],
  mh: ['mh', 'Marshallese'],
  mi: ['mi', 'Maori'],
  mk: ['mk', 'Macedonian'],
  ml: ['ml', 'Malayalam'],
  mn: ['mn', 'Mongolian'],
  mr: ['mr', 'Marathi'],
  ms: ['ms', 'Malay'],
  mt: ['mt', 'Maltese'],
  my: ['my', 'Burmese'],
  na: ['na', 'Nauru'],
  nb: ['nb', '"Bokmål, Norwegian"'],
  nd: ['nd', '"Ndebele, North"'],
  ne: ['ne', 'Nepali'],
  ng: ['ng', 'Ndonga'],
  nl: ['nl', 'Dutch'],
  nn: ['nn', '"Norwegian Nynorsk"'],
  no: ['no', 'Norwegian'],
  nr: ['nr', '"Ndebele, South"'],
  nv: ['nv', 'Navajo'],
  ny: ['ny', 'Chichewa'],
  oc: ['oc', 'Occitan (post 1500)'],
  oj: ['oj', 'Ojibwa'],
  om: ['om', 'Oromo'],
  or: ['or', 'Oriya'],
  os: ['os', 'Ossetian'],
  pa: ['pa', 'Panjabi'],
  pi: ['pi', 'Pali'],
  pl: ['pl', 'Polish'],
  ps: ['ps', 'Pushto'],
  pt: ['pt', 'Portuguese'],
  qu: ['qu', 'Quechua'],
  rm: ['rm', 'Romansh'],
  rn: ['rn', 'Rundi'],
  ro: ['ro', 'Romanian'],
  ru: ['ru', 'Russian'],
  rw: ['rw', 'Kinyarwanda'],
  sa: ['sa', 'Sanskrit'],
  sc: ['sc', 'Sardinian'],
  sd: ['sd', 'Sindhi'],
  se: ['se', 'Northern Sami'],
  sg: ['sg', 'Sango'],
  si: ['si', 'Sinhala'],
  sk: ['sk', 'Slovak'],
  sl: ['sl', 'Slovenian'],
  sm: ['sm', 'Samoan'],
  sn: ['sn', 'Shona'],
  so: ['so', 'Somali'],
  sq: ['sq', 'Albanian'],
  sr: ['sr', 'Serbian'],
  ss: ['ss', 'Swati'],
  st: ['st', 'Sotho, Southern'],
  su: ['su', 'Sundanese'],
  sv: ['sv', 'Swedish'],
  sw: ['sw', 'Swahili'],
  ta: ['ta', 'Tamil'],
  te: ['te', 'Telugu'],
  tg: ['tg', 'Tajik'],
  th: ['th', 'Thai'],
  ti: ['ti', 'Tigrinya'],
  tk: ['tk', 'Turkmen'],
  tl: ['tl', 'Tagalog'],
  tn: ['tn', 'Tswana'],
  to: ['to', 'Tonga (Tonga Islands)'],
  tr: ['tr', 'Turkish'],
  ts: ['ts', 'Tsonga'],
  tt: ['tt', 'Tatar'],
  tw: ['tw', 'Twi'],
  ty: ['ty', 'Tahitian'],
  ug: ['ug', 'Uighur'],
  uk: ['uk', 'Ukrainian'],
  ur: ['ur', 'Urdu'],
  uz: ['uz', 'Uzbek'],
  ve: ['ve', 'Venda'],
  vi: ['vi', 'Vietnamese'],
  vo: ['vo', 'Volapük'],
  wa: ['wa', 'Walloon'],
  wo: ['wo', 'Wolof'],
  xh: ['xh', 'Xhosa'],
  yi: ['yi', 'Yiddish'],
  yo: ['yo', 'Yoruba'],
  za: ['za', 'Zhuang'],
  zh: ['zh', 'Chinese'],
  zu: ['zu', 'Zulu'],
};

export const languageValues = [
  'ca', 'cs',
  'da', 'de',
  'el', 'en', 'en-GB', 'en-IE', 'en-MY', 'en-NL', 'en-SG', 'en-TH', 'es-419', 'es-ES',
  'fr',
  'hu',
  'it',
  'ja',
  'ko',
  'nl', 'nn', 'no',
  'pl', 'pt-BR', 'pt-PT',
  'ru',
  'sv',
  'th', 'tr',
  'zh-Hans', 'zh-Hant',
];

export const localeValues = [
  'ar-IL', 'ar-SA', 'as-US',
  'ca-ES', 'cs-CZ',
  'da-DK', 'de-AT', 'de-CH', 'de-DE', 'de-GE',
  'el-GR', 'en-AD', 'en-AE', 'en-AM', 'en-AT', 'en-AU', 'en-AZ', 'en-BA', 'en-BE', 'en-BG', 'en-BH', 'en-BY', 'en-CA', 'en-CH', 'en-CY', 'en-CZ', 'en-DE', 'en-DK',
  'en-EE', 'en-EN', 'en-ES', 'en-FI', 'en-FR', 'en-GB', 'en-GE', 'en-GR', 'en-HK', 'en-HR', 'en-HU', 'en-IE', 'en-IL', 'en-IN', 'en-IT', 'en-JO', 'en-JP', 'en-KG',
  'en-KW', 'en-KZ', 'en-LB', 'en-LT', 'en-LV', 'en-MD', 'en-MK', 'en-MX', 'en-MY', 'en-NL', 'en-NO', 'en-NZ', 'en-OM', 'en-PH', 'en-PL', 'en-PT', 'en-QA', 'en-RO',
  'en-RS', 'en-RU', 'en-SA', 'en-SE', 'en-SG', 'en-SK', 'en-TM', 'en-TR', 'en-UA', 'en-US', 'en-UZ', 'en-ZA', 'es-AR', 'es-CL', 'es-ES', 'es-MX', 'es-UY',
  'fi-FI', 'fr-BE', 'fr-CH', 'fr-FR', 'fr-IT',
  'he-IL', 'hu-HU',
  'it-CH', 'it-IT',
  'ja-JP',
  'ko-KR',
  'nl-BE', 'nl-NL', 'nn-NO', 'no-NO',
  'pl-PL', 'po-PL', 'po-PT', 'pt-BR', 'pt-PT', 'pu-AF',
  'ru-RU',
  'sk-SK', 'sp-AR', 'sp-CL', 'sp-ES', 'sv-SE',
  'th-TH', 'tr-TR',
  'zh-CN', 'zh-EN', 'zh-HK', 'zh-MO', 'zh-SG', 'zh-TW', 'zn-CN',
];

export const locationTypes = [
  { label: 'DISTRIBUTION_CENTER', value: 'DC' },
  { label: 'RETAIL_LOCATION', value: 'STORE' },
  { label: 'SATELLITE_DISTRIBUTION_CENTER', value: 'SDC' },
];

export const orderCategories = ['A', 'F', 'I', 'O'];

export const partnerNameValues = [
  '',
  'A3', 'A3 SPORT LLC', 'ABDURRAHMAN SPOR', 'ACTIVE', 'ADELAIDE', 'ADRENALINE', 'AF1', 'AJU SPORTS', 'ALIRAVCI', 'ANDONG', 'ANDREANE', 'AOLONGSHIBO', 'ARCO', 'ARDENBERG', 'ARDENSPORT', 'ASDEX', 'ATASPOR', 'ATC', 'ATLETIK', 'AUSTRIA SPORT FRANCHISE', 'AXO',
  'BARCIN', 'BARCIN SPOR', 'BASMAN', 'BD SHENGSHI', 'BDC', 'BDI', 'BELEP', 'BELGIUM FRANCHISE', 'BELGIUM PM UNITED BRANDS', 'BELGIUM UNITED BRANDS SIS', 'BONGDEOK', 'BUYUK GAZEL',
  'CAMP India Private Limited', 'CC YUJIE', 'CELENIS SPORT TEAM', 'CHILGOK', 'CHILSEONG', 'CHUNCHEON', 'CIRCLE OF SPORT', 'COM INTERNACIONAL', 'CONSORCIO MISTER T SA DE CV', 'CV. MITRA SEJAHTERA', 'CZASZYNSKI',
  'DABRA', 'DAEJEON JEIL', 'DAERIM SPORTS DS BETTER', 'DAESAN SPORTS', 'DAEYEON', 'DARIM', 'DAVOS 95 SRL -ITALY FRCH', 'DELTA SPORT', 'DENIZ TEKSTIL', 'DENMARK FRANCHISE', 'DIAZ TAPIA SL', 'Dicks Sporting Goods', 'DINAPA', 'DISTANCE', 'DONGDAEGU SPORTS', 'DONGHUI',
  'EIBLE TYLER HOLDINGS', 'ELDERBERRY', 'ELIZINN', 'EO SPORTS', 'EPIC SPORTS', 'Equinox Chile Sociedad por Acciones', 'ESPORGAL', 'EUNGWANG',
  'FAYA', 'FENGLEI', 'FERIVI', 'FF GROUP BULGARIA', 'FF GROUP ROMANIA', 'FFG', 'FNP', 'FOOT LOCKER', 'FOX', 'FOX WIZEL LTD', 'FRANCE FRANCHISE PARTNER', 'FRANCHISE BURCHBUCHLER',
  'GALVE & GARRIDO S.L.U', 'GANGNEUNG', 'GAVIA', 'GERMANY ADDVALUE', 'GERMANY P&C SIS', 'GOODBABY', 'GRAHA', 'GRUPO AFEET', 'GTI', 'GWANGJANG', 'GYEONGSAN', 'GYEONGSEONG BUGYEONG',
  'HADDAD', 'HAETAE SANGSA SINJANGAN', 'HALL SARL', 'HANHWA GALLERIA DEPT BEST', 'HANYEONG YUTONG YEONGTONG', 'HIGHWAVE SPORTS', 'HYUNDAI',
  'IN MODA', 'INNOVA', 'INVERSIONES MURANO', 'IRI', 'ITAEWON TOWN', 'ITALY ADAMANTE SRL', 'ITALY PERCASSI WOMENS', "ITALY PERCASSI-L'INNOMINA", 'ITALY RETAIL SPORT',
  'JALAJALG', 'JANGNIM SPORTS', 'JB', 'JD', 'JECHEON', 'JEOMCHON', 'JEONJU SPORTS', 'JERASIA FASHION', 'JIEZHIXING', 'JIN SPORTS', 'JUNGNANG',
  'KEMALER', 'KORAY SPOR', 'KU HOOPS', 'Karmali Virjee',
  'LEMGREG', 'LEMONIS', 'LW NON-NRBV',
  'MAGUIRE GROUP HOLDINGS, INC', 'MAKS SPORT', 'MARSSPORT', 'MASAN HAPSEONG', 'MASPLAY', 'MELELOUDIS', 'MERCURIAL', 'MG-SPORT', 'MIR SPORTA', 'MOKPO SPORTS', 'MOURATIDIS', 'MUNHEUNG', 'MUNSAN', 'MUTLU SPOR', 'Mystique Athleisure Retail Pvt Ltd',
  'NANUM SPORTS GWANGJU', 'NARA', 'NAUN SPORTS', 'NEGEDLY GMBH', 'NETHERLANDS ARDENBURG', 'NEW SEONGAN', 'NORDSTROM', 'NORWAY ANTON SPORT',
  'OFEK', 'OY MPH TRADING LTD', 'Oracle Retail India',
  'P&L BELEP', 'PAIR', 'PARTNER FOULON', 'PERCASSI', 'PERFORMANS', 'PERMASHOP', 'PERMASHOP GMBH', 'PI AND CO', 'PLAYGROUND 360', 'PORTUGAL FASHION PARK', 'PORTUGAL UNIFATE', 'PORTUGAL UNIFATO', 'POUSHENG', 'PRO SPORT', 'PRO: DIRECT', 'PT GIORDANO', 'PT GIORDANO INDONESIA',
  'QD BOCHENG',
  'REALLY', 'RESTORE LLC', 'RETAILORS LTD. (FOX)', 'RETAILORS SPORTS INC', 'RJ CORP', 'RJ Corp Ltd', 'RPG', 'RUMBO S.A', 'RUN AND FUN SPORTS', 'RX SPORTS',
  'SAMURAY SPOR', 'SANGJU', 'SANSE', 'SARILAR', 'SARL BROTHERS', 'SELECUBE', 'SHAANXI LIHE', 'SHOELAND', 'SHUANGJIAN', 'SIJI SPORTS', 'SINHYEON SPORTS', 'SINJEJU', 'SMH', 'SOKCHO', 'SOLARIS SPORT S.R.L.', 'SONGTAN', 'Southbay SRL', 'SPAIN EL NUMERITO', 'SPAIN ESPORGAL', 'SPAIN HOBBY FRANQUICIA', 'SPAIN RETAIL EXPERIENCE', 'SPORT & MODA', 'SPORT EYBL', 'SPORT TIME', 'SPORTAMORE', 'SPORTIME', 'SPORTINN', 'SPORTLAND OY', 'SPORTS MEDIA', 'SPORTS SEVEN VALLEY', 'SPORTSHOP AMSTERDAM', 'SPORTSLAND', 'SPORTSLORDS', 'SPORTSYSTEM AG', 'SSIPL', 'SSIPL Retail Ltd', 'STADIUM', 'SUHYEON 21', 'SUN & SAND SPORTS', 'SUNCHEON', 'SUTL', 'SUWON SPORTS', 'SWITZERLAND DOSENBACH', 'Saffron Lifestyle Traders',
  'TAEBAEK', 'TAEJIN SPORTS', 'TAR CA', 'TEAM SPORT', 'TOPSPORTS', 'TRIMERA', 'TRIMERA GROUP', 'TRIMERA ISRAEL', 'TUTKA',
  'UK PLANMARK WOMENS', 'UK PLANMARK-PAUL FENLON', 'UNIFATO', 'UNITED SPORTS OF LEBANON', 'UPNRUN',
  'VALIRAM', 'VARNER', 'VENTURE YUTONG', 'VICTORY SPORTS', 'VILADOMAT S.A',
  'WINWIN', 'WONJU',
  'YALI SPOR', 'YAR LLC', 'YEONGCHEON', 'YEONGJU', 'YN YUCHENG',
  'ZALANDO',
];

export const regionCodeValues = ['AMER', 'APLA', 'EU', 'GC', 'NA'];

export const regionIdMappings = {
  18: 'Greater China',
  19: 'Hong Kong',
  27: 'Tai Wan',
  29: 'Mexico',
  3: 'North America',
  4: 'Europe',
  8: 'Japan',
};

export const regionValues = [
  'ASIA_PACIFIC_LATIN_AMERICA',
  'EUROPE_MIDDLE_EAST_AFRICA',
  'EMERGING_MARKETS',
  'GREATER_CHINA',
  'NORTH_AMERICA',
];

export const regionIds = {
  CONVERSE: {
    NORTH_AMERICA: '82fa2b53-4578-4639-8ee7-2b4ce2dd9afc',
  },
  NIKE: {
    ASIA_PACIFIC_LATIN_AMERICA: 'dc99749d-da1e-45c3-a798-0e38f143469b',
    EUROPE_MIDDLE_EAST_AFRICA: '3350ffa3-adb8-4981-8a98-5dc7fddd581c',
    GREATER_CHINA: 'ea242a8c-464a-4991-99e7-1ada3dd4fa41',
    NORTH_AMERICA: 'a469ace0-9f91-4c5e-95bb-37f5403a61f0',
  },
};

export const salesAreas = [
  { label: 'AGS', value: 'AGS' },
  { label: 'ARGENTINA', value: 'ARGENTINA' },
  { label: 'CE', value: 'CE' },
  { label: 'CE_JS', value: 'CE_JS' },
  { label: 'CE_SHNFS', value: 'CE_SHNFS' },
  { label: 'CE_SW', value: 'CE_SW' },
  { label: 'CHILE', value: 'CHILE' },
  { label: 'CN_BJNFS', value: 'CN_BJNFS' },
  { label: 'CN_NE', value: 'CN_NE' },
  { label: 'CS_GZ', value: 'CS_GZ' },
  { label: 'CS_XE', value: 'CS_XE' },
  { label: 'CW_YG', value: 'CW_YG' },
  { label: 'DEFAULT', value: 'DEFAULT' },
  { label: 'DIGITAL', value: 'NA_DIGITAL' },
  { label: 'EMP_CENTRAL', value: 'NA_EMPLOYEE_CENTRAL' },
  { label: 'EMP_NORTHWEST', value: 'NA_EMPLOYEE_NORTHWEST' },
  { label: 'FRANCE', value: 'FRANCE' },
  { label: 'GC_FACTORY_EAST_1', value: 'EAST_1' },
  { label: 'GC_FACTORY_EAST_2', value: 'EAST_2' },
  { label: 'GC_FACTORY_EAST_3', value: 'EAST_3' },
  { label: 'GC_FACTORY_EAST_4', value: 'EAST_4' },
  { label: 'GC_FACTORY_EAST_5', value: 'EAST_5' },
  { label: 'GC_FACTORY_EAST_6', value: 'EAST_6' },
  { label: 'GC_FACTORY_NORTH_1', value: 'NORTH_1' },
  { label: 'GC_FACTORY_NORTH_2', value: 'NORTH_2' },
  { label: 'GC_FACTORY_NORTH_3', value: 'NORTH_3' },
  { label: 'GC_FACTORY_NORTH_4', value: 'NORTH_4' },
  { label: 'GC_FACTORY_NORTH_5', value: 'NORTH_5' },
  { label: 'GC_FACTORY_NORTH_6', value: 'NORTH_6' },
  { label: 'GC_FACTORY_NORTH_7', value: 'NORTH_7' },
  { label: 'GC_FACTORY_NORTH_8', value: 'NORTH_8' },
  { label: 'GC_FACTORY_SOUTH_1', value: 'SOUTH_1' },
  { label: 'GC_FACTORY_SOUTH_2', value: 'SOUTH_2' },
  { label: 'GC_FACTORY_SOUTH_3', value: 'SOUTH_3' },
  { label: 'GC_FACTORY_SOUTH_4', value: 'SOUTH_4' },
  { label: 'GC_FACTORY_SOUTH_5', value: 'SOUTH_5' },
  { label: 'GC_INLINE_EAST', value: 'EAST' },
  { label: 'GC_INLINE_NORTH', value: 'NORTH' },
  { label: 'GC_INLINE_SOUTH', value: 'SOUTH' },
  { label: 'GC_STORES_HONGKONG', value: 'HONGKONG' },
  { label: 'GC_STORES_MACAU', value: 'MACAU' },
  { label: 'GC_STORES_TAIWAN', value: 'TAIWAN' },
  { label: 'GREECE', value: 'GREECE' },
  { label: 'IBERIA', value: 'IBERIA' },
  { label: 'ISRAEL', value: 'ISRAEL' },
  { label: 'ITALY', value: 'ITALY' },
  { label: 'MEXICO', value: 'MEXICO' },
  { label: 'NFS_CENTRAL', value: 'NA_FACTORY_CENTRAL' },
  { label: 'NFS_NORTHEAST', value: 'NA_FACTORY_NORTHEAST' },
  { label: 'NFS_SOUTHEAST', value: 'NA_FACTORY_SOUTHEAST' },
  { label: 'NFS_SOUTHWEST', value: 'NA_FACTORY_SOUTHWEST' },
  { label: 'NFS_WEST', value: 'NA_FACTORY_WEST' },
  { label: 'NORTHERN_EUROPE', value: 'NORTHERN_EUROPE' },
  { label: 'NSO_CENTRAL', value: 'NA_INLINE_CENTRAL' },
  { label: 'NSO_EAST', value: 'NA_INLINE_EAST' },
  { label: 'NSO_NORTHEAST', value: 'NA_INLINE_NORTHEAST' },
  { label: 'NSO_NORTHWEST', value: 'NA_INLINE_NORTHWEST' },
  { label: 'NSO_SOUTHEAST', value: 'NA_INLINE_SOUTHEAST' },
  { label: 'NSO_SOUTHWEST', value: 'NA_INLINE_SOUTHWEST' },
  { label: 'NSO_WEST', value: 'NA_INLINE_WEST' },
  { label: 'POLAND', value: 'POLAND' },
  { label: 'RUSSIA', value: 'RUSSIA' },
  { label: 'TURKEY', value: 'TURKEY' },
  { label: 'UK_IRELAND', value: 'UK_IRELAND' },
  { label: 'URUGUAY', value: 'URUGUAY' },
];

export const stateValues = [
  { label: 'Alabama', value: 'AL' },
  { label: 'Alaska', value: 'AK' },
  { label: 'American Samoa', value: 'AS' },
  { label: 'Arizona', value: 'AZ' },
  { label: 'Arkansas', value: 'AR' },
  { label: 'California', value: 'CA' },
  { label: 'Colorado', value: 'CO' },
  { label: 'Connecticut', value: 'CT' },
  { label: 'Delaware', value: 'DE' },
  { label: 'District of Columbia', value: 'DC' },
  { label: 'Federated States of Micronesia', value: 'FM' },
  { label: 'Florida', value: 'FL' },
  { label: 'Georgia', value: 'GA' },
  { label: 'Guam', value: 'GU' },
  { label: 'Hawaii', value: 'HI' },
  { label: 'Idaho', value: 'ID' },
  { label: 'Illinois', value: 'IL' },
  { label: 'Indiana', value: 'IN' },
  { label: 'Iowa', value: 'IA' },
  { label: 'Kansas', value: 'KS' },
  { label: 'Kentucky', value: 'KY' },
  { label: 'Louisiana', value: 'LA' },
  { label: 'Maine', value: 'ME' },
  { label: 'Marshall Islands', value: 'MH' },
  { label: 'Maryland', value: 'MD' },
  { label: 'Massachusetts', value: 'MA' },
  { label: 'Michigan', value: 'MI' },
  { label: 'Minnesota', value: 'MN' },
  { label: 'Mississippi', value: 'MS' },
  { label: 'Missouri', value: 'MO' },
  { label: 'Montana', value: 'MT' },
  { label: 'Nebraska', value: 'NE' },
  { label: 'Nevada', value: 'NV' },
  { label: 'New Hampshire', value: 'NH' },
  { label: 'New Jersey', value: 'NJ' },
  { label: 'New Mexico', value: 'NM' },
  { label: 'New York', value: 'NY' },
  { label: 'North Carolina', value: 'NC' },
  { label: 'North Dakota', value: 'ND' },
  { label: 'Northern Mariana Islands', value: 'MP' },
  { label: 'Ohio', value: 'OH' },
  { label: 'Oklahoma', value: 'OK' },
  { label: 'Oregon', value: 'OR' },
  { label: 'Palau', value: 'PW' },
  { label: 'Pennsylvania', value: 'PA' },
  { label: 'Puerto Rico', value: 'PR' },
  { label: 'Rhode Island', value: 'RI' },
  { label: 'South Carolina', value: 'SC' },
  { label: 'South Dakota', value: 'SD' },
  { label: 'Tennessee', value: 'TN' },
  { label: 'Texas', value: 'TX' },
  { label: 'Utah', value: 'UT' },
  { label: 'Vermont', value: 'VT' },
  { label: 'Virginia', value: 'VA' },
  { label: 'Virgin Islands', value: 'VI' },
  { label: 'Washington', value: 'WA' },
  { label: 'West Virginia', value: 'WV' },
  { label: 'Wisconsin', value: 'WI' },
  { label: 'Wyoming', value: 'WY' },
];

export const canadaProvinceValues = [
  'Alberta',
  'British Columbia',
  'Manitoba',
  'New Brunswick',
  'Newfoundland and Labrador',
  'Nova Scotia',
  'Ontario',
  'Prince Edward Island',
  'Quebec',
  'Saskatchewan',
].map((province) => ({
  label: province,
  value: province,
}));

export const storeChannelValues = ['DIGITAL'];

export const storeConceptValues = [
  'BEACON', 'CLEARANCE', 'COMMUNITY',
  'FACTORY', 'HOI', 'JORDAN',
  'KICKS LOUNGE', 'LIVE',
  'NIKE LAB', 'Nike Style', 'RISE', 'Nike Unite', 'Well Collective',
];

export const storeDistricts = [
  'APLA_JP_CENTRAL', 'APLA_JP_EAST', 'APLA_JP_NSO', 'APLA_JP_WEST', 'APLA_JP_NSP',
  'APLA_KR_NVS_DISTRICT_1', 'APLA_KR_NSO', 'APLA_KR_NVS_DISTRICT_2',
  'APLA_MX_DISTRICT_1', 'APLA_MX_DISTRICT_2', 'APLA_MX_DISTRICT_3', 'APLA_MX_DISTRICT_4',
  'APLA_PAC_DISTRICT_1', 'APLA_SEA_SG_NVS', 'APLA_SEA_SG_NSO', 'APLA_SEA_MY_NVS',
  'APLA_SEA_TH_NVS',
  'EMEA_CENTRAL_EUROPE',
  'EMEA_PARIS_NORTH', 'EMEA_PARIS_SOUTH',
  'EMEA_FRANCE_CENTRAL', 'EMEA_FRANCE_EAST', 'EMEA_FRANCE_NORTH', 'EMEA_FRANCE_SE', 'EMEA_FRANCE_SW',
  'EMEA_GER_MID_SWTZ', 'EMEA_GER_SOUTH_AUS',
  'EMEA_BERLIN', 'EMEA_GERMANY_N_W',
  'EMEA_GREECE',
  'EMEA_HOI',
  'EMEA_ISRAEL',
  'EMEA_ITALY_NE', 'EMEA_ITALY_NW', 'EMEA_MILAN', 'EMEA_ITALY_SOUTH', 'EMEA_MADRID', 'EMEA_ROME',
  'EMEA_NE_MID', 'EMEA_NE_NORTH', 'EMEA_NE_SOUTH',
  'EMEA_POLAND_NORTH', 'EMEA_POLAND_SOUTH',
  'EMEA_RUSSIA_MOSCOW', 'EMEA_RUSSIA_ST_PETE',
  'EMEA_SOUTH_AFRICA',
  'EMEA_SPAIN_CENTRAL', 'EMEA_SPAIN_EAST', 'EMEA_BARCELONA', 'EMEA_PORTUGAL',
  'EMEA_TURKEY', 'EMEA_TURKEY_ISTANBUL',
  'EMEA_UK_EAST', 'EMEA_UK_IRELAND_WEST', 'EMEA_LONDON_NSO', 'EMEA_LONDON_NVS', 'EMEA_UK_MIDLANDS', 'EMEA_UK_NORTH', 'EMEA_UK_NORTH_WEST', 'EMEA_NTL', 'EMEA_UK_SOUTH',
  'GC_CE_SH', 'GC_CE_JS', 'GC_CE_JZ', 'GC_CE_NSO', 'GC_CE_SW', 'GC_CN_BJ',
  'GC_CN_BJNSO', 'GC_CN_JJ', 'GC_CN_LY', 'GC_CN_NE',
  'GC_CS_MGQ', 'GC_CS_NSO', 'GC_CS_GZ', 'GC_CS_SZ', 'GC_CS_XE', 'GC_CW_CY', 'GC_CW_NW', 'GC_CW_YG',
  'GC_HK_MC', 'GC_HK_NSO', 'GC_TW', 'GC_TW_NSO', 'INLINE', 'MID_ATLANTIC',
  '1_NFS_OREGON', '2_NFS_WASHINGTON', '3_NFS_BAY_AREA', '4_NFS_NORTH_LA', '5_NSO_LA',
  '6_NFS_SAN_DIEGO_INLAND_EMPIRE', '7_NSO_NORTHERN_CA_OR_WA', '8_NFS_NORTHERN_CA', '9_NFS_SOUTH_LA_OC', '10_NFS_SOUTH_TEXAS',
  '11_NFS_DESERT_STATES', '12_NFS_MOUNTAIN_STATES', '13_NFS_NORTH_TEXAS', '14_NFS_NV_UT', '15_NSO_TEXAS',
  '16_NSO_DESERT_MOUNTAIN', '17_NFS_EAST_TEXAS', '18_NFS_MIDWEST', '19_NFS_GREAT_LAKES', '20_NFS_CHICAGO_METRO',
  '21_NFS_OHIO_VALLEY', '22_NFS_WEST_CANADA', '23_NFS_EAST_CANADA', '24_NSO_CENTRAL', '25_NSO_OHIO',
  '26_NFS_CAROLINAS', '27_NFS_ORLANDO', '28_NFS_SOUTHERN_FLORIDA', '29_NFS_TENNESSEE', '30_NFS_GULF_STATES',
  '31_NFS_GEORGIA', '32_NFS_SOUTHEAST', '33_CENTRAL_FLORIDA', '34_NSO_FLORIDA', '35_NFS_NEW_ENGLAND',
  '36_NFS_LONG_ISLAND_BROOKLYN', '37_NSO_NYC', '38_NFS_NORTHERN_JERSEY_UPSTATE_NY', '39_NFS_JERSEY_PHILLY', '40_NFS_VIRGINIA',
  '41_NSO_NEW_ENGLAND', '42_NSO_NJ_DMV', '43_NFS_MARYLAND', 'NORTHEAST', 'NORTHERN_EUROPE', 'PNW_CENTRAL',
  'SOUTHEAST', 'SOUTHERN_EUROPE', 'SOUTHWEST', 'WEST',
];

export const storeStatusValues = [
  { label: 'Closed', value: 'CLOSED' },
  { label: 'Open', value: 'OPEN' },
  { label: 'Unopened', value: 'UNOPENED' },
];

export const storeSubConcepts = [
  { label: 'BRAND_EXPERIENCE', value: 6 },
  { label: 'CATEGORY', value: 7 },
  { label: 'CLEARANCE', value: 4 },
  { label: 'COMMUNITY', value: 2 },
  { label: 'DIGITAL', value: 9 },
  { label: 'DISTRIBUTION_CENTER', value: 10 },
  { label: 'EMPLOYEE', value: 3 },
  { label: 'FACTORY', value: 5 },
  { label: 'FRANCHISE', value: 13 },
  { label: 'NIKELAB', value: 1 },
  { label: 'POP_UP_STORE', value: 17 },
  { label: 'REGIONAL_DISTRIBUTION_CENTER', value: 16 },
];

export const storeTerritories = [
  'APLA_JP', 'APLA_KR', 'APLA_MX', 'APLA_PAC', 'APLA_SEA',
  'CONVERSE',
  'CONVERSE_WE',
  'EMEA_CENTRAL', 'EMEA_EAST_AFRICA', 'EMEA_FRANCE', 'EMEA_SOUTH', 'EMEA_WEST',
  'GC_CE', 'GC_CN', 'GC_CS', 'GC_CW', 'GC_HK_TW_MC', 'GC_HK_NSO', 'GC_CE_SHNSO', 'GC_CN_BJNSO', 'GC_CS_NSO', 'GC_TW_NSO',
  'NA_CENTRAL', 'NA_NORTHEAST', 'NA_SOUTHEAST', 'NA_SOUTHWEST', 'NA_WEST',
];

export const storeTypeValues = [
  '',
  'FACTORY', 'FRANCHISEE_PARTNER_STORE',
  'INLINE',
  'MONO_BRAND_NON_FRANCHISEE_PARTNER_STORE', 'MULTI_BRAND_NON_FRANCHISEE_PARTNER_STORE',
];

export const timezoneValues = momentTZ.tz
  .names()
  .filter((el) => /[a-z]+([_a-z]*[a-z]+)?\/[a-z]+([_a-z]*[a-z]+)?$/i.test(el));

export const typeValues = [
  'Beacon',
  'Clearance', 'Converse',
  'Employee Store',
  'Hurley',
  'Inline Store',
  'Nike Factory Stores', 'Nike Partner Store', 'Nike Running', 'Nike Stores',
  'Standard',
];

export const warehouseCompanyValues = ['Nike', 'Converse', 'FootLocker', 'Nordstrom', 'TopSports'];

export const warehouseIPTypeValues = ['PHYSICAL', 'LOGICAL'];

export const warehouseRegionValues = [
  'ASIA_PACIFIC_LATIN_AMERICA',
  'EMERGING_MARKETS',
  'EUROPE_MIDDLE_EAST_AFRICA',
  'GREATER_CHINA',
  'NORTH_AMERICA',
];

export const warehouseTypeValues = [
  'DISTRIBUTION_CENTER',
  'SATELLITE_DISTRIBUTION_CENTER',
];

export const warehouseChannelValues = [
  'GRPPRCH',
  'NIKE.COM',
  'TMALL_FGSP',
  'TMALL_JD',
  'TMALL_HPMK',
  'TMALL_OUTL',
  'TMALL_YA',
];

export const loginClassificationValues = [
  'NSO_CLOUD',
  'NSO_CORP',
  'NSO_1POS',
  'NSO_IDP',
  'NSP_1POS',
  'NSP_IDP',
  'NONE',
];
