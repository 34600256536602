{
  "client_id": "nike.rcffus.retailoperations",
  "dcnAuditResource": "/dcnaudit",
  "directiveAnalyzerResource": "/directive-analyzer/directive-analyzer",
  "dwDevicesResource": "/devicesandwaitlines/devices",
  "dwTicketsResource": "/devicesandwaitlines/tickets",
  "dwWaitlinesResource": "/devicesandwaitlines/waitlines",
  "emailResource": "/email",
  "generatePriceDCNResource": "/pricefeed",
  "generateProductDCNResource": "/generatedcn/generatedcn",
  "generateProductDCNStatusResource": "/generatedcn/dcnGtinStatus",
  "hierarchyNodesResource": "/sls/hierarchy-nodes",
  "o2oCapabilitiesSFSResource": "/onlinetooffline/sfs",
  "patchResource": "/patch",
  "posReasonsResource": "/posreasons/reason",
  "printerModelResource": "/printerportal/printer-models",
  "printerResource": "/printerportal/printers",
  "productAnalyzerResource": "/productanalyzer/product-analyzer",
  "qrCodeGeneratorCampaigns": "/qrcodegenerator/campaigns",
  "qrCodeGeneratorHistory": "/qrcodegenerator/history",
  "qrCodeGeneratorResource": "/qrcodegenerator",
  "qrCodeShortenURL": "/qrcodegenerator/shorten-url",
  "response_type": "id_token token",
  "rltWorkstationNumberServiceResource": "/rlt/registers",
  "scope": "profile openid email",
  "scsDefinitionResource": "/scs/definition",
  "scsReport": "/scs/report",
  "scsHistory": "/scs/history",
  "scsResource": "/scs/scs",
  "slsAuthTokenResource": "/sls/auth-token",
  "slsCmpSync": "/sls/cmp-sls-sync",
  "slsDigitalStoreResource": "/sls/digital-stores/digitalstoreid?storeId=",
  "slsDigitalStoresResource": "/sls/digital-stores",
  "slsStoreOfferingIdResource": "/sls/store-offerings/id",
  "slsStoreOfferingResource": "/sls/store-offerings",
  "slsStoreOfferingTranslationResource": "/sls/store-offering-translations",
  "slsStoreResource": "/sls/stores/storeid?storeId=",
  "slsStoreVersionsResource": "/sls/stores/versions",
  "slsStoresBulkCreateResource": "/sls/stores/bulkcreate",
  "slsStoresResource": "/sls/stores",
  "slsWarehouseResource": "/sls/warehouses/warehouseid?storeId=",
  "slsWarehousesResource": "/sls/warehouses",
  "sniTransactionSearchResource": "/transactionsearch/transactionsearch",
  "sniTransactionSearchSNIForwarderResource": "/transactionsearch/sniforwarder",
  "storeServicesResource": "/store-services/store-services",
  "storeServicesTopicsResource": "/store-services/topics",
  "storeServicesTypesResource": "/store-services/store-service-types",
  "storeviewsResource": "/store/store_views/v2",
  "taxIdentifiersResource": "/taxidentifiers",
  "trafficGenerateResource": "/traffic/retail-traffic-reports",
  "trafficReloadResource": "/traffic/retail-next-historical",
  "trafficViewResource": "/traffic/traffic-service",
  "transactionAnalyzerResource": "/transactionanalyzer/transactionanalyzer",
  "transactionAnalyzerV2Resource": "/transactionanalyzerv2/transactionanalyzerv2",
  "zoneLocalizationsResource": "/zones/localizations",
  "zonesResource": "/zones/zones"
}
